import { AuthControl } from "../../authControl";

import { AdminAdsService, AdminAdsServiceImpl } from "../adminServices/adminAdsService";
import { AdminEventsService, AdminEventsServiceImpl } from "../adminServices/adminEventsService";
import { AdminProfilesService, AdminProfilesServiceImpl } from "../adminServices/adminProfilesService";
import { AdminUsersService, AdminUsersServiceImpl } from "../adminServices/adminUsersService";
import { AdminOperationsService, AdminOperationsServiceImpl } from "../adminServices/adminOperationsService";
import { AdminRoadtripsService, AdminRoadtripsServiceImpl } from "../adminServices/adminRoadtripsService";
import { AdminCampgroundsService, AdminCampgroundsServiceImpl } from "../adminServices/adminCampgroundsService";
import { AdminOpenAIAssistantsService, AdminOpenAIAssistantsServiceImpl } from "../adminServices/adminOpenAIAssistantsService";
import { AdminOpenAIThreadsService, AdminOpenAIThreadsServiceImpl } from "../adminServices/adminOpenAIThreadsService";
import { AdminOpenAIMessagesService, AdminOpenAIMessagesServiceImpl } from "../adminServices/adminOpenAIMessagesService";
import { AdminActionsService, AdminActionsServiceImpl } from "../adminServices/adminActionsService";

export interface AdminServices {
  readonly adminActionsService: AdminActionsService;
  readonly adminAdsService: AdminAdsService;
  readonly adminEventsService: AdminEventsService;
  readonly adminProfilesService: AdminProfilesService;
  readonly adminUsersService: AdminUsersService;
  readonly adminOperationsService: AdminOperationsService;
  readonly adminRoadtripsService: AdminRoadtripsService;
  readonly adminCampgroundsService: AdminCampgroundsService;
  readonly adminOpenAIAssistantsService: AdminOpenAIAssistantsService;
  readonly adminOpenAIThreadsService: AdminOpenAIThreadsService;
  readonly adminOpenAIMessagesService: AdminOpenAIMessagesService;
};

export class AdminServicesImpl implements AdminServices {

  public readonly adminActionsService: AdminActionsService;
  public readonly adminAdsService: AdminAdsService;
  public readonly adminEventsService: AdminEventsService;
  public readonly adminProfilesService: AdminProfilesService;
  public readonly adminUsersService: AdminUsersService;
  public readonly adminOperationsService: AdminOperationsService;
  public readonly adminRoadtripsService: AdminRoadtripsService;
  public readonly adminCampgroundsService: AdminCampgroundsService;
  public readonly adminOpenAIAssistantsService: AdminOpenAIAssistantsService;
  public readonly adminOpenAIThreadsService: AdminOpenAIThreadsService;
  public readonly adminOpenAIMessagesService: AdminOpenAIMessagesService;
  
  constructor( apiServer: string, authControl: AuthControl) {
    this.adminActionsService = new AdminActionsServiceImpl(apiServer, authControl);
    this.adminAdsService = new AdminAdsServiceImpl(apiServer, authControl);
    this.adminEventsService = new AdminEventsServiceImpl(apiServer, authControl);
    this.adminProfilesService = new AdminProfilesServiceImpl(apiServer, authControl);
    this.adminUsersService = new AdminUsersServiceImpl(apiServer, authControl);
    this.adminOperationsService = new AdminOperationsServiceImpl(apiServer, authControl);
    this.adminRoadtripsService = new AdminRoadtripsServiceImpl(apiServer, authControl);
    this.adminCampgroundsService = new AdminCampgroundsServiceImpl(apiServer, authControl);
    this.adminOpenAIAssistantsService = new AdminOpenAIAssistantsServiceImpl(apiServer, authControl);
    this.adminOpenAIThreadsService = new AdminOpenAIThreadsServiceImpl(apiServer, authControl);
    this.adminOpenAIMessagesService = new AdminOpenAIMessagesServiceImpl(apiServer, authControl);
  }
  
}


export function createClientAdminServices(apiServer: string, authControl: AuthControl): AdminServices {
  return new AdminServicesImpl(apiServer, authControl);
}

export type { AdminAdsService } from "../adminServices/adminAdsService";
export type { AdminEventsService } from "../adminServices/adminEventsService";
export type { AdminProfilesService } from "../adminServices/adminProfilesService";
export type { AdminUsersService } from "../adminServices/adminUsersService";
export type { AdminOperationsService } from "../adminServices/adminOperationsService";
export type { AdminRoadtripsService } from "../adminServices/adminRoadtripsService";
export type { AdminCampgroundsService } from "../adminServices/adminCampgroundsService";
export type { AdminOpenAIAssistantsService } from "../adminServices/adminOpenAIAssistantsService";
export type { AdminOpenAIThreadsService } from "../adminServices/adminOpenAIThreadsService";
export type { AdminOpenAIMessagesService } from "../adminServices/adminOpenAIMessagesService";
export type { AdminActionsService } from "../adminServices/adminActionsService";

