// https://vike.dev/onCreateApp
export { onCreateApp }

import type { OnCreateAppSync } from 'vike-vue/types'
import { App } from "vue";

import TextClamp from 'vue3-text-clamp';
import vue3GoogleLogin from 'vue3-google-login'
import VueSanitize from 'vue-sanitize-directive'
import { UidPlugin } from '@shimyshack/uid'

import { createGoogleService } from "../services/googleService";
import { createPhotosService } from "../services/photosService";
import { usePrerender } from "../composables/prerender";
import { useAuthControl } from '../composables/authControl';
import { provideServices } from '../composables/services';
import { provideAdminServices } from '../composables/adminServices';
import { createPrerenderAdminServices } from '../services/prerenderServices/adminServices';
import { createPrerenderStubServices } from '../services/prerenderServices/stubServices';


const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;

const googleService = createGoogleService();

const { isPrerendering } = usePrerender();

if(!isPrerendering) {
  googleService.loadGoogle();
}

const { userState, authControl } = useAuthControl();

function setupAppDependencies(app: App<Element>) {
  app.provide('userState', userState);
  app.provide('authControl', authControl);

  app.provide('googleService', googleService);

  const photosService = createPhotosService(
    authControl,
    googleService
  );
  app.provide('photosService', photosService);
  
  app.use(TextClamp);
  app.use(VueSanitize);
  app.use(UidPlugin)    
};


const onCreateApp: OnCreateAppSync = (pageContext): ReturnType<OnCreateAppSync> => {


  const { app } = pageContext

  if(isPrerendering) {

    provideServices(createPrerenderStubServices());
    provideAdminServices(createPrerenderAdminServices());  

  } else {
    
    app.use(vue3GoogleLogin, {
      clientId: googleClientId,
      buttonConfig: {
        text: "continue_with"
      }
    });
  
  }


  setupAppDependencies(app);
 }

