import { Action, ActionPropsUser } from "../../models/action";
import { AuthControl } from "../../authControl"
import { ref } from "vue";
import { Service, ServiceImpl } from "../service";
import { ResponseJSON } from "../serviceUtils";

export interface AdminActionsService extends Service {
  createAction(actionParams: ActionPropsUser): Promise<Action>;
  updateAction(actionId: string, action: ActionPropsUser): Promise<Action>;
  getAction(actionId: string): Promise<Action>;
  getActions(): Promise<Array<Action>>;
  deleteAction(actionId: string): Promise<void>;
}

type ActionsResponseJSON = ResponseJSON & {
  actions?: Array<Action>
}

type ActionResponseJSON = ResponseJSON & Action;

function actionFromResponseJSON (responseJSON: ActionResponseJSON): Action {
  delete responseJSON.status;
  return responseJSON;
}

function actionsFromResponseJSON (responseJSON: ActionsResponseJSON): Array<Action> {
  return responseJSON.actions || [];
}

export class AdminActionsServiceImpl extends ServiceImpl implements AdminActionsService {
  public readonly actions = ref<Array<Action>>();

  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  }
  
  async createAction(actionParams: ActionPropsUser): Promise<Action> {

    return await this.post(
      '/admin/v1/actions', 
      actionParams,
      actionFromResponseJSON
    )

  }

  async getAction(actionId: string): Promise<Action> {
    return await this.get(`/admin/v1/actions/${actionId}`, actionFromResponseJSON);
  }

  async getActions(): Promise<Action[]> {
    return await this.get('/admin/v1/actions', actionsFromResponseJSON);
  }

  async updateAction(actionId: string, action: ActionPropsUser): Promise<Action> {
    return await this.patch(`/admin/v1/actions/${actionId}`, action, actionFromResponseJSON);
  }

  async deleteAction(actionId: string): Promise<void> {
    return await this.delete(`/admin/v1/actions/${actionId}`)
  }

}
