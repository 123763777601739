import { AuthControl } from "../../authControl";
import { Roadtrip } from "../../models/roadtrip";
import { roadtripFromResponseJSON } from "../roadtripsService";
import { Service, ServiceImpl } from "../service";

export interface AdminRoadtripsService extends Service {
  getRoadtrip(roadtripId: string): Promise<Roadtrip>;
}

export class AdminRoadtripsServiceImpl extends ServiceImpl implements AdminRoadtripsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  };

  async getRoadtrip(roadtripId: string): Promise<Roadtrip> {
    return await this.get(`/admin/v1/roadtrips/${roadtripId}`, roadtripFromResponseJSON);
  }
};