import { Service, ServiceImpl } from "./service";
import { AuthControl } from "../authControl"

export const FEEDBACK_EVENT_TYPE = "feedback";

export const ACTION_PRESENTED_EVENT_TYPE = "actionPresented";
export const ACTION_TAKEN_EVENT_TYPE = "actionTaken";

export const COOKIE_CONSENT_PRESENTED_EVENT_TYPE = "cookieConsentPresented";
export const COOKIES_ACCEPTED_EVENT_TYPE = "cookiesAccepted";

export const SIGN_IN_STARTED_EVENT_TYPE = "signInStarted";
export const SIGN_IN_COMPLETED_EVENT_TYPE = "signInCompleted";
export const SIGN_IN_FAILED_EVENT_TYPE = "signInFailed";
export const SIGN_IN_CANCELED_EVENT_TYPE = "signInCanceled";

export const SIGN_UP_STARTED_EVENT_TYPE = "signUpStarted";
export const SIGN_UP_COMPLETED_EVENT_TYPE = "signUpCompleted";
export const SIGN_UP_FAILED_EVENT_TYPE = "signUpFailed";
export const SIGN_UP_CANCELED_EVENT_TYPE = "signUpCanceled";

export const CAMPGROUND_PRESENTED_EVENT_TYPE = "campgroundPresented"

export type ActionEventParams = {
  actionId: string,
  roadtripId?: string,
  stopId?: string,
  campgroundId?: string
}

export type CookiesAcceptedEventParams = {
  strictlyNecessary: boolean, 
  functionality: boolean,
  targeting: boolean,
  tracking: boolean
}

export type FeedbackEventParams = {
  featureId: string,
  roadtripId?: string,
  stopId?: string,
  binaryFeedback?: boolean,
  reason?: string,
  otherText?: string  
}
export interface EventsService extends Service {
  feedbackEvent(params: FeedbackEventParams): Promise<void>;

  actionPresentedEvent(params: ActionEventParams): Promise<void>;

  actionTakenEvent(params: ActionEventParams): Promise<void>;

  cookieConsentPresentedEvent(): Promise<void>;
  cookiesAcceptedEvent(params: CookiesAcceptedEventParams): Promise<void>;

  signInStartedEvent(): Promise<void>;
  signInCompletedEvent(): Promise<void>;
  signInFailedEvent(): Promise<void>;
  signInCanceledEvent(): Promise<void>;

  signUpStartedEvent(): Promise<void>;
  signUpCompletedEvent(): Promise<void>;
  signUpFailedEvent(): Promise<void>;
  signUpCanceledEvent(): Promise<void>;

  campgroundPresentedEvent(campgroundId: string): Promise<void>;
}

export class EventsServiceImpl extends ServiceImpl implements EventsService {
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
    this.allowUnauthenticated = true;
  }
  
 
  async feedbackEvent(params: FeedbackEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: FEEDBACK_EVENT_TYPE, 
      ...params
    });
  }

  async actionPresentedEvent(params: ActionEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: ACTION_PRESENTED_EVENT_TYPE, 
      ...params
    });
  }

  async actionTakenEvent(params: ActionEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: ACTION_TAKEN_EVENT_TYPE,
      ...params
    });
  }

  async cookieConsentPresentedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: COOKIE_CONSENT_PRESENTED_EVENT_TYPE,
    });
  }

  async cookiesAcceptedEvent(params: CookiesAcceptedEventParams): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: COOKIES_ACCEPTED_EVENT_TYPE,
      ...params
    });
  }

  async signInStartedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_IN_STARTED_EVENT_TYPE,
    });
  };

  async signInCompletedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_IN_COMPLETED_EVENT_TYPE,
    });
  };

  async signInFailedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_IN_FAILED_EVENT_TYPE,
    });
  };

  async signInCanceledEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_IN_CANCELED_EVENT_TYPE,
    });
  };

  async signUpStartedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_UP_STARTED_EVENT_TYPE,
    });
  };

  async signUpCompletedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_UP_COMPLETED_EVENT_TYPE,
    });
  };

  async signUpFailedEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_UP_FAILED_EVENT_TYPE,
    });
  };

  async signUpCanceledEvent(): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: SIGN_UP_CANCELED_EVENT_TYPE,
    });
  };

  async campgroundPresentedEvent(campgroundId: string): Promise<void> {
    return await this.post(`/v1/events`, {
      eventType: CAMPGROUND_PRESENTED_EVENT_TYPE,
      campgroundId
    });
  };

}
