import { ref } from "vue";
import { Services } from "..";
import { Affiliate } from "../../models/affiliate";
import { AlertUserProps, Alert } from "../../models/alert";
import { Interest } from "../../models/interest";
import { Profile, ProfileUserProps } from "../../models/profile";
import { Roadtrip } from "../../models/roadtrip";
import { Stop } from "../../models/stop";
import { TakeActionParams } from "../actionsService";
import { GetAdParams, Ad } from "../adsService";
import { ActionEventParams, CookiesAcceptedEventParams, FeedbackEventParams } from "../eventsService";
import { CreateProfileParams } from "../profilesService";
import { ReferralRequestOptions } from "../referralsService";
import { CreateRoadtripParams, GetRoadtripsResult } from "../roadtripsService";
import { CreateStopParams, UpdateStopDatesParams } from "../stopsService";
import { LatLng, TimezoneInfo } from "../timezonesService";
import { Campground } from "../../models/campground";
import { FindCampgroundsParams } from "../campgroundsService";


export function createPrerenderStubServices(): Services {
  return { ...prerenderServiceStubs };
}

export const prerenderServiceStubs: Services = {
  roadtripsService: {
    populateRoadtrips: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    populateRoadtrip: function (roadtripId: string): Promise<void> {
      throw new Error("Function not implemented.");
    },
    createRoadtrip: function (roadtripParams: CreateRoadtripParams): Promise<Roadtrip> {
      throw new Error("Function not implemented.");
    },
    getRoadtrips: function (limit?: number | undefined, deleted?: boolean | undefined): Promise<GetRoadtripsResult> {
      throw new Error("Function not implemented.");
    },
    getRoadtrip: function (roadtripId: string): Promise<Roadtrip> {
      throw new Error("Function not implemented.");
    },
    restoreRoadtrip: function (roadtrip: Roadtrip): Promise<any> {
      throw new Error("Function not implemented.");
    },
    addStopToRoadtrip: function (roadtrip: Roadtrip, stop: Stop): Promise<Roadtrip> {
      throw new Error("Function not implemented.");
    },
    updateRoadtripStops: function (roadtrip: Roadtrip, stops: Stop[]): Promise<Roadtrip> {
      throw new Error("Function not implemented.");
    },
    updateRoadtripTitle: function (roadtrip: Roadtrip, title: string): Promise<Roadtrip> {
      throw new Error("Function not implemented.");
    },
    deleteRoadtrip: function (roadtrip: Roadtrip): Promise<void> {
      throw new Error("Function not implemented.");
    },
    deleteRoadtrips: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    shareRoadtrip: function (roadtrip: Roadtrip, email: string): Promise<Roadtrip> {
      throw new Error("Function not implemented.");
    },
    roadtrips: ref([]),
    deletedRoadtrips: ref([]),
    roadtrip: ref(),
    isLoading: ref(false),
  },
  stopsService: {
    populateStop: function (stopId: string): Promise<void> {
      throw new Error("Function not implemented.");
    },
    createStop: function (createStopParams: CreateStopParams): Promise<Stop> {
      throw new Error("Function not implemented.");
    },
    getStop: function (stopId: string): Promise<Stop> {
      throw new Error("Function not implemented.");
    },
    updateStopDates: function (stopId: string, dates: UpdateStopDatesParams): Promise<Stop> {
      throw new Error("Function not implemented.");
    },
    stop: ref(),
    isLoading: ref(false),
  },
  profilesService: {
    populateProfile: function (forceRefresh?: boolean | undefined): Promise<void> {
      throw new Error("Function not implemented.");
    },
    createProfile: function (params?: CreateProfileParams | undefined): Promise<Profile> {
      throw new Error("Function not implemented.");
    },
    getProfile: function (): Promise<Profile> {
      throw new Error("Function not implemented.");
    },
    updateProfile: function (profileId: string, profile: ProfileUserProps): Promise<Profile> {
      throw new Error("Function not implemented.");
    },
    getStripeSessionUrl: function (): Promise<string> {
      throw new Error("Function not implemented.");
    },
    addStripeSubscription: function (profileId: string, stripeSessionId: string): Promise<Profile> {
      throw new Error("Function not implemented.");
    },
    cancelSubscription: function (profileId: string): Promise<Profile> {
      throw new Error("Function not implemented.");
    },
    isEarlyAdopter: ref(false),
    isAdmin: ref(false),
    emailVerified: ref(false),
    hasSubscription: ref(false),
    enabledFeatures: ref([]),
    showDynamicRoadtripMaps: ref(false),
    profile: ref(),
    isLoading: ref(false),
  },
  interestsService: {
    populateInterests: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    getInterests: function (): Promise<Interest[]> {
      throw new Error("Function not implemented.");
    },
    interests: ref([]),
    isLoading: ref(false),
  },
  referralsService: {
    requestReferral: function (affiliateId: string, options: ReferralRequestOptions): Promise<void> {
      throw new Error("Function not implemented.");
    },
    getNextReferralAffiliate: function (): Promise<Affiliate> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
  },
  affiliatesService: {
    populateAffiliates: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    getAffiliates: function (): Promise<Affiliate[]> {
      throw new Error("Function not implemented.");
    },
    affiliates: ref([]),
    isLoading: ref(false),
  },
  timezonesService: {
    getTimezoneInfo: function (latLng: LatLng): Promise<TimezoneInfo> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
  },
  eventsService: {
    isLoading: ref(false),

    feedbackEvent: function (params: FeedbackEventParams): Promise<void> {
      throw new Error("Function not implemented.");
    },
    actionPresentedEvent: function (params: ActionEventParams): Promise<void> {
      throw new Error("Function not implemented.");
    },
    actionTakenEvent: function (params: ActionEventParams): Promise<void> {
      throw new Error("Function not implemented.");
    },
    cookieConsentPresentedEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    cookiesAcceptedEvent: function (params: CookiesAcceptedEventParams): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signInStartedEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signInCompletedEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signInFailedEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signInCanceledEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signUpStartedEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signUpCompletedEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signUpFailedEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    signUpCanceledEvent: function (): Promise<void> {
      throw new Error("Function not implemented.");
    },
    campgroundPresentedEvent: function (campgroundId: string): Promise<void> {
      throw new Error("Function not implemented.");
    },
  },
  adsService: {
    getAd: function (params?: GetAdParams | undefined): Promise<Ad> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
  },
  actionsService: {
    startAction: function (): void {
      throw new Error("Function not implemented.");
    },
    takeAction: function (params: TakeActionParams): Promise<void> {
      throw new Error("Function not implemented.");
    },
    completeAction: function (): void {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
  },
  alertsService: {
    getAlerts: function (): Promise<Alert[]> {
      throw new Error("Function not implemented.");
    },
    updateAlert: function (alertId: string, alertProps: AlertUserProps): Promise<Alert> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
  },
  campgroundsService: {
    getCampground: function (campgroundId: string): Promise<Campground | undefined> {
      throw new Error("Function not implemented.");
    },
    findCampgrounds: function (params: FindCampgroundsParams): Promise<Array<Campground>> {
      throw new Error("Function not implemented.");
    },
    isLoading: ref(false),
  }
};
