import { useAuthControl } from "../composables/authControl";
import { provideServices } from "../composables/services";
import { provideAdminServices } from "../composables/adminServices";
import { createClientServices } from "../services";
import { createClientAdminServices } from "../services/adminServices";

const apiServer = import.meta.env.VITE_ERTP_API_SERVER;

const { authControl } = useAuthControl();

provideServices(createClientServices(apiServer, authControl));
provideAdminServices(createClientAdminServices(apiServer, authControl));


