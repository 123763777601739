import { 
  AdminServices, 
} from "../services/adminServices";

let adminServices: AdminServices;

export function provideAdminServices(providedAdminServices: AdminServices) {
  adminServices = providedAdminServices;
}

export function useAdminServices() {
  return { ...adminServices }
};
