import { 
  Services,
} from "../services";

let services: Services;

export function provideServices(providedServices: Services) {
  services = providedServices;
}

export function hasServices() {
  return !!services;
}

export function useServices() {
  return { ...services }
};
