import { ActionProps } from "../models/action";

/**
 * Checks for and rejects some of the most common errors users make when entering email addresses.
 * This is a simple heuristic, and does NOT formally validate an email address according to the RFCs.
 * 
 * @param emailAddress The email address to check
 * 
 * @returns true if emailAddress is possibly valid, false if emailAddress is invalid specifically due to a missing '@' symbol or '.' symbol.
 */
export function possiblyValidEmailAddress(emailAddress: string | null | undefined) {
  return (emailAddress ?? "").length > 0
    && String(emailAddress)
    .toLowerCase()
    .match(/^\S+@\S+\.\S+$/) != null;
};

/**
 * 
 */
const validUrlTemplateRegExp = /^([^\x00-\x20\x7f"'%<>\\^`{|}]|%[0-9A-Fa-f]{2}|{[+#./;?&=,!@|]?((\w|%[0-9A-Fa-f]{2})(\.?(\w|%[0-9A-Fa-f]{2}))*(:[1-9]\d{0,3}|\*)?)(,((\w|%[0-9A-Fa-f]{2})(\.?(\w|%[0-9A-Fa-f]{2}))*(:[1-9]\d{0,3}|\*)?))*})*$/;

/**
 * Checks for and rejects strings that are empty, not defined, or otherwise do not conform to the ABNF rules in Section 2 of RFC 6570
 * 
 * @param urlTemplate The urlTemplate to check
 * @returns true if urlTemplate conforms to the ABNF rules in Section 2 of RFC 6570 
 */
export function validUrlTemplate(urlTemplate: string | null | undefined) {
  return (urlTemplate ?? "").length > 0
    && validUrlTemplateRegExp.test(urlTemplate!)
}

/**
 * Returns true for those actions that require a valid URL Template
 */
export function requiresUrlTemplate(actionProps: ActionProps) {
  switch(actionProps.actionKind) {
    case "followlink":
    case "recreationgov":
    case "findcampflaresites":
      return (actionProps.isRoadtripAction || actionProps.isStopAction);
  }

}


/**
 * Determine what must be done (if anything) to make an action valid.
 * 
 * @param action Action to validate
 * 
 * @returns a string describing what needs to be done to make the action valid, or 
 *  undefined if the action is valid
 */
export function actionValidationMessage(action: ActionProps) {
  if (!action.actionKind) return "Missing required 'actionKind'";
  if (!action.name) return "Missing required 'name'";
  if (!action.description) return "Missing required 'description'";
  if (!action.text) return "Missing required 'text'";
   
  switch(action.actionKind) {
    case "followlink":
    case "recreationgov":
    case "findcampflaresites":
      if ((action.isRoadtripAction || action.isStopAction) && !action.urlTemplate) return "Missing required 'urlTemplate'";
      break;
  }

  if (!action.isRoadtripAction && !action.isStopAction && !action.isCampgroundAction) {
    return "At least one of 'isRoadtripAction', 'isStopAction', or 'isCampgroundAction' must be true."
  }

  if (action.actionKind == "signupforalerts" && (action.isRoadtripAction || action.isCampgroundAction)) return "For 'signupforalerts', only 'isStopAction' is allowed.";
  if (action.actionKind == "signupforalerts" && !action.isStopAction) return "'isStopAction' is required for all 'signupforalerts' actions.";

}
