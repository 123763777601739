<template>
  <div v-if="resultMessage" class="alert text-center mt-3" :class="alertClass" role="alert">
    {{ resultMessage }}
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useResultMessage } from "../composables/resultMessage";

const alertClass = computed(() => {
  return resultOK?.value ? 'alert-info' : 'alert-warning'
})

const { resultMessage, resultOK } = useResultMessage();
</script>


