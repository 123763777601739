import { AuthControl } from "../../authControl"
import { Service, ServiceImpl } from "../service";

export const ADMIN_NOTE_EVENT_TYPE = "adminNote";

export type NoteEventParams = {
  text: string  
}

export interface AdminEventsService extends Service {
  createNoteEvent(params: NoteEventParams): Promise<void>;
}

export class AdminEventsServiceImpl extends ServiceImpl implements AdminEventsService {

  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  }
  
  async createNoteEvent(params: NoteEventParams): Promise<void> {

    return await this.post(`/admin/v1/events`, {
      eventType: ADMIN_NOTE_EVENT_TYPE, 
      ...params
    });
  }

}
