import { AuthControl } from "../../authControl";
import { OpenAIMessage } from "../../models/openAIMessage";
import { Service, ServiceImpl } from "../service";
import { ResponseJSON } from "../serviceUtils";

export interface AdminOpenAIMessagesService extends Service {
  getOpenAIMessages(openAIThreadId: string): Promise<Array<OpenAIMessage>>;
}

type OpenAIMessagesResponseJSON = ResponseJSON & { messages: Array<OpenAIMessage> };

function openAIMessagesFromResponseJSON(responseJSON: OpenAIMessagesResponseJSON): Array<OpenAIMessage> {
  return responseJSON.messages;
};

export class AdminOpenAIMessagesServiceImpl extends ServiceImpl implements AdminOpenAIMessagesService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  }

  async getOpenAIMessages(openAIThreadId: string): Promise<Array<OpenAIMessage>> {
    return await this.get(`/admin/v1/openAIThreads/${openAIThreadId}/openAIMessages`, openAIMessagesFromResponseJSON);
  }

};
