import { Ref } from "vue";
import { AuthControl } from "../../authControl";
import { User } from "../../models/user";
import { ResponseJSON } from "../serviceUtils";
import { Service, ServiceImpl } from "../service";

export interface AdminUsersService extends Service {
  getUserByEmail(email: string): Promise<User | undefined>;
  getUserByUserId(userId: string): Promise<User | undefined>;
  isLoading: Ref<boolean>
}

type UsersResponseJSON = ResponseJSON & {
  recordsCount: number,
  users?: Array<User>
}

function userFromResponseJSON(responseJSON: UsersResponseJSON): User {
  if (!responseJSON) throw Error('No response.');
  if (responseJSON.status != "ok") {
    throw Error(responseJSON.message);
  }
  if (responseJSON.recordsCount > 1) throw Error('More than one user returned.')
  if (responseJSON.users && responseJSON.users.length > 1) throw Error('More than one user returned.')
  return (responseJSON.users || [])[0];
}



export class AdminUsersServiceImpl extends ServiceImpl implements AdminUsersService {
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl);
  };


  async getUserByEmail(email: string): Promise<User> {
    const searchParams = new URLSearchParams({"email": email});
    return await this.get(`/admin/v1/users`, userFromResponseJSON, searchParams);
  }

  async getUserByUserId(userId: string): Promise<User> {
    const searchParams = new URLSearchParams({"userId": userId});
    return await this.get(`/admin/v1/users`, userFromResponseJSON, searchParams);
  }

};