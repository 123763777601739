<template>
  <div v-if="show" class="modal" ref="signInModalRef" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sign In</h5>
          <button type="button" class="btn-close" @click="onCancel" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ERTPSignInForm 
            emailAddressInputId="ERTPSignInModalEmailAddressInputId"
            passwordInputId="ERTPSignInModalPasswordInputId"
            @on-success="onSuccess"
            @on-error="onError"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import ERTPSignInForm from '../forms/ERTPSignInForm.vue';
import { useBootstrap } from '../../composables/bootstrap';
import { useServices } from '../../composables/services';

const { 
  showModal,
  hideModal,  
} = useBootstrap();

const {
  eventsService
} = useServices();

const emit = defineEmits<{
  onSuccess: [],
  onCancel: [],
  'update:show': [value: boolean]
}>();


const props = defineProps<{show: boolean | undefined}>();

const signInModalRef = ref<HTMLDivElement>();

watch(
  [() => props.show, signInModalRef], 
  () => {
    if (!signInModalRef.value) return; // probably not mounted
    if (props.show) {
      eventsService.signInStartedEvent();
      showModal(signInModalRef, () => {
        if (props.show) { onCancel(); }
      });
    } else {
      hideModal(signInModalRef);
    }
  }, 
  {immediate: true}
);

function onCancel() {
  eventsService.signInCanceledEvent();
  emit('onCancel')
  emit('update:show', false);
}

function onSuccess() {
  eventsService.signInCompletedEvent();
  emit('onSuccess')
  emit('update:show', false);
}

function onError() {
  eventsService.signInFailedEvent();
}
</script>

