import { computed } from "vue";
import { useUserState } from "../composables/userState";
import { useServices } from './services';
import { useRoadtrips } from "./roadtrips";
import { useProfile } from "./profile";
import { 
  DEFAULT_MAX_SIGNEDIN_ROADTRIPS,
} from '../models/profile';


export function useSubscriptionFunnel() {

  const { userState } = useUserState();
  const { roadtripsService, profilesService } = useServices();
  const {
    roadtrips,
  } = useRoadtrips();
  const {
    profile,
  } = useProfile();
  
  const mustSignUpForMoreRoadtrips = computed(() => {
    if(!userState.value.isSignedIn) return false; // Only show this if they're signed in ANONYMOUSLY
    if(!userState.value.isAnonymous) return false; // Already signed in non-anonymously, signing in won't do any good.
    if(!roadtrips.value?.length) return false; // They haven't created the first road trip yet, they don't need to sign in
    return true;
  });
  
  const mustSubscribeForMoreRoadtrips = computed(() => {
    if(userState.value.loading) return false; // Don't need to encourage them to sign in if they're in the midst of auth action
    if(userState.value.isAnonymous) return false; // They're anonymous, they CAN'T subscribe; so don't tell them to.
    if(!userState.value.isSignedIn) return false; // They're not signed in, they CAN'T subscribe; so don't tell them to
    if(profilesService.isLoading.value) return false; // Loading; can't decide yet if they need to subscribe to create more roadtrips 
    if(profilesService.hasSubscription.value) return false; // They already have a subscription, subscribing again won't help them.
    if(!profile.value) return false; // No profile, so they have to sign up before they can subscribe (for more road trips)
    if(roadtripsService.isLoading.value) return false; // We don't know what to compare against (yet)
    // Similarly to the above sanity check, this SHOULDN'T happen, by design, but if it does, deal with it ratioanlly
    if(!roadtrips.value) { console.warn('Roadtrips are loaded, but empty - no way to determine how many we have.'); return false; }
  
    const maxAllowedRoadtrips = profile.value.maxAllowedRoadtrips || DEFAULT_MAX_SIGNEDIN_ROADTRIPS;
    return (roadtrips.value.length >= maxAllowedRoadtrips);
  });
  
  return { 
    mustSignUpForMoreRoadtrips,
    mustSubscribeForMoreRoadtrips,
  }
};
