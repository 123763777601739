import { AuthControl } from "../../authControl";
import { OpenAIAssistant } from "../../models/openAIAssistant";
import { Service, ServiceImpl } from "../service";
import { ResponseJSON } from "../serviceUtils";

export interface AdminOpenAIAssistantsService extends Service {
  getOpenAIAssistant(openAIAssistantId: string): Promise<OpenAIAssistant>;
  getOpenAIAssistants(): Promise<Array<OpenAIAssistant>>;
}

type OpenAIAssistantResponseJSON = ResponseJSON & OpenAIAssistant;

function openAIAssistantFromResponseJSON(responseJSON: OpenAIAssistantResponseJSON): OpenAIAssistant {
  delete responseJSON.status;
  const openAIAssistant: OpenAIAssistant = responseJSON;

  return openAIAssistant;
};

type OpenAIAssistantsResponseJSON = ResponseJSON & { assistants: Array<OpenAIAssistant> };

function openAIAssistantsFromResponseJSON(responseJSON: OpenAIAssistantsResponseJSON): Array<OpenAIAssistant> {
  return responseJSON.assistants;
};

export class AdminOpenAIAssistantsServiceImpl extends ServiceImpl implements AdminOpenAIAssistantsService {  
  constructor(
    apiServer: string,
    authControl: AuthControl
  ) {
    super(apiServer, authControl)
  }

  async getOpenAIAssistants(): Promise<Array<OpenAIAssistant>> {
    return await this.get(`/admin/v1/openAIAssistants`, openAIAssistantsFromResponseJSON);
  }

  async getOpenAIAssistant(openAIAssistantId: string): Promise<OpenAIAssistant> {
    return await this.get(`/admin/v1/openAIAssistants/${openAIAssistantId}`, openAIAssistantFromResponseJSON);
  }
};