import { AuthControl } from "../authControl";

import { AdsService, AdsServiceImpl } from "./adsService";
import { ActionsService, ActionsServiceImpl } from "./actionsService";
import { AffiliatesService, AffiliatesServiceImpl } from "./affiliatesService";
import { AlertsService, AlertsServiceImpl } from "./alertsService";
import { CampgroundsService, CampgroundsServiceImpl } from "./campgroundsService";
import { EventsService, EventsServiceImpl } from "./eventsService";
import { InterestsService, InterestsServiceImpl } from "./interestsService";
import { ProfilesService, ProfilesServiceImpl } from "./profilesService";
import { ReferralsService, ReferralsServiceImpl } from "./referralsService";
import { RoadtripsService, RoadtripsServiceImpl } from "./roadtripsService";
import { StopsService, StopsServiceImpl } from "./stopsService";
import { TimezonesService, TimezonesServiceImpl } from "./timezonesService";


export interface Services {
  readonly roadtripsService: RoadtripsService;
  readonly stopsService: StopsService;
  readonly profilesService: ProfilesService;
  readonly interestsService: InterestsService;
  readonly referralsService: ReferralsService;
  readonly affiliatesService: AffiliatesService;
  readonly timezonesService: TimezonesService;
  readonly eventsService: EventsService;
  readonly adsService: AdsService;
  readonly actionsService: ActionsService;
  readonly alertsService: AlertsService;
  readonly campgroundsService: CampgroundsService;
}
export class ServicesImpl implements Services {
  public readonly roadtripsService: RoadtripsService;
  public readonly stopsService: StopsService;
  public readonly profilesService: ProfilesService;
  public readonly interestsService: InterestsService;
  public readonly referralsService: ReferralsService;
  public readonly affiliatesService: AffiliatesService;
  public readonly timezonesService: TimezonesService;
  public readonly eventsService: EventsService;
  public readonly adsService: AdsService;
  public readonly actionsService: ActionsService;
  public readonly alertsService: AlertsService;
  public readonly campgroundsService: CampgroundsService;
  
  constructor(apiServer: string, authControl: AuthControl) {

    this.roadtripsService = new RoadtripsServiceImpl(
      apiServer,
      authControl,
    );

    this.stopsService = new StopsServiceImpl(
      apiServer,
      authControl
    );
  
    this.profilesService = new ProfilesServiceImpl(
      apiServer,
      authControl
    );
  
    this.interestsService = new InterestsServiceImpl(
      apiServer,
      authControl
    );
  
    this.referralsService = new ReferralsServiceImpl(
      apiServer, 
      authControl
    );
  
    this.affiliatesService = new AffiliatesServiceImpl(
      apiServer, 
      authControl
    );
  
    this.timezonesService = new TimezonesServiceImpl(
      apiServer
    );
  
    this.eventsService = new EventsServiceImpl(
      apiServer,
      authControl
    );
  
    this.adsService = new AdsServiceImpl(
      apiServer,
      authControl
    );
  
    this.actionsService = new ActionsServiceImpl(
      apiServer,
      authControl
    );
  
    this.alertsService = new AlertsServiceImpl(
      apiServer,
      authControl
    )    

    this.campgroundsService = new CampgroundsServiceImpl(
      apiServer, 
      authControl
    )

  }
};

export function createClientServices(apiServer: string, authControl: AuthControl): Services {
  return new ServicesImpl(apiServer, authControl);
}

export type { RoadtripsService } from "./roadtripsService";
export type { StopsService } from "./stopsService";
export type { ProfilesService } from "./profilesService";
export type { InterestsService } from "./interestsService";
export type { ReferralsService } from "./referralsService";
export type { AffiliatesService } from "./affiliatesService";
export type { TimezonesService } from "./timezonesService";
export type { EventsService } from "./eventsService";
export type { AdsService } from "./adsService";
export type { ActionsService } from "./actionsService";
